import React from "react";
import "./App.css";

const App = () => {
  return (
    <div className="container">
      <div className="content">
        <h1 className="main-heading">Arel Wedding Düğün Salonları</h1>
        <p className="sub-heading">Sayfamız şu anda yapım aşamasında. En kısa zamanda tekrar ziyaret edin.</p>
        <div className="contact-info">
          <h4 className="info">Adres: Anadolu bulvarı Anatolia İş merkezi No:139/96-97</h4>
          <h4 className="info">Tel: 0542 578 0050</h4>
          <h4 className="info">Sabit: 0312 256 7 256</h4>
        </div>
        <p className="footer"><small>Dmt Bilişim Medya Reklam</small></p>
      </div>
    </div>
  );
};

export default App;
